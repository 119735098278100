// Credit to Vinny for this awesome glass animation
// https://codepen.io/VinnyFonseca/pen/bpVLpa
import React, { useEffect } from "react";
import "./Glass.css";

export function Glass() {
  useEffect(() => {
    // make some waves.
    const liquid = document.getElementById("liquid"),
      waveWidth = 8,
      waveCount = Math.floor(240 / waveWidth); //240 being liquid css width

    for (var i = 0; i < waveCount; i++) {
      if (liquid) {
        var wave = document.createElement("div");
        wave.className += " wave";
        liquid.appendChild(wave);
        wave.style.left = i * waveWidth + "px";
        wave.style.webkitAnimationDelay = i / 110 + "s";
      }
    }
  });

  return (
    <div className="glass-container">
      <div className="glass">
        <svg
          height="256"
          version="1.1"
          viewBox="0 0 256 256"
          width="256"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g transform="translate(0,-796.36216)">
            <path d="m 0.00382458,809.37043 3.44199432,190.24456 0,28.06851 c 0,0 -3.06211838,12.166 20.2809851,17.4661 0,0 110.957396,16.2169 197.049066,0 0,0 29.4618,-5.6101 29.8501,-18.7085 l 4.97305,-203.66219 0.3912,-15.28002 -4.98439,-2.81304 c 0,0 -34.43772,-9.35539 -106.76072,-8.10616 0,0 105.99256,1.86694 106.76072,11.85611 0,0 2.6767,1.55006 -12.62569,4.0508 0,0 -112.49664,8.73077 -167.972682,4.98539 0,0 101.385612,8.42996 169.115432,-1.24233 0,0 11.86573,-3.43536 11.86573,5.60772 0,0 0.3883,48.03304 -0.38279,53.33075 0,0 -3.82475,111.35299 -17.60161,121.9553 l -2.67647,1.56381 c 0,0 -25.63704,-12.48532 -63.51677,-11.2361 0,0 63.13112,15.59466 35.20593,23.07856 0,0 -14.16496,8.7397 -153.435848,1.8783 0,0 -47.8289423,-7.1761 5.738594,-20.57999 0,0 -14.159415,-0.3238 -31.381165,5.92002 0,0 -11.10018,-13.72306 -13.014003,-42.72843 L 4.2142305,812.7943 48.207107,817.17117 c 0,0 -41.3219539,-4.68457 -41.3219539,-7.49072 0,0 -6.50145258,-5.30461 47.0663099,-9.98001 0,0 -46.3034406,-1.25381 -53.94763842,9.66999" />
          </g>
        </svg>

        <div id="liquid"></div>
        <div className="cube-wrapper">
          <div className="cube one"></div>
          <div className="cube two"></div>
          <div className="cube three"></div>
        </div>
        <div className="shadow"></div>
      </div>

      <footer>
        Credit for glass to{" "}
        <a href="https://codepen.io/VinnyFonseca/pen/bpVLpa">Vinny Fonseca </a>
      </footer>
    </div>
  );
}
