import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";

const timezoneNames = moment.tz.names();

export function Timezones() {
  const [localHour, setLocalHour] = useState(new Date().getHours());
  const [now, setNow] = useState(new Date());
  const [relativeTimezones, setRelativeTimezones] = useState<string[]>([]);
  const nowIntervalHandle = useRef(-1);
  const randomHappyHour = useRef("");

  // Update `now` every second, `localHour` on the hour, and `randomHappyHour`
  // every 3rd second.
  useEffect(() => {
    nowIntervalHandle.current = window.setInterval(() => {
      const internalNow = new Date();
      if (internalNow.getHours() !== localHour) {
        setLocalHour(now.getHours());
      }
      if (internalNow.getSeconds() % 3 === 0) {
        randomHappyHour.current =
          relativeTimezones[
            Math.floor(Math.random() * relativeTimezones.length)
          ];
      }
      setNow(internalNow);
    }, 1000);
    return () => clearInterval(nowIntervalHandle.current);
  }, [
    localHour,
    now,
    randomHappyHour,
    relativeTimezones,
    setLocalHour,
    setNow
  ]);

  // Update the list of available timezones when `localHour` changes
  useEffect(() => {
    const zones: string[] = [];
    for (var i in timezoneNames) {
      const timezone = moment().tz(timezoneNames[i]);
      const hour = timezone.hour();
      const population = (timezone as any)._z.population as number;
      if (hour === 17 && population && !timezoneNames[i].includes("Etc")) {
        zones.push(timezoneNames[i]);
      }
    }
    setRelativeTimezones(zones);
  }, [localHour, setRelativeTimezones]);

  return (
    <>
      <h1>{now.toLocaleTimeString()}</h1>
      <h2>It's 5pm in {relativeTimezones.length} other locales!</h2>
      <p>
        {randomHappyHour.current ? (
          <a
            href={`https://www.google.com/maps/place/${encodeURIComponent(
              randomHappyHour.current
            )}`}
            target="happy-hour-maps"
          >
            {randomHappyHour.current
              .replace(/^[^/]+\//, "")
              .replace(/[^a-zA-Z0-9]+/g, " ")}
          </a>
        ) : (
          <>&hellip;</>
        )}
      </p>
    </>
  );
}
