import React from "react";
import { Timezones } from "./Timezones";
import "./App.css";
import { Glass } from "./Glass";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>It's 5 O'Clock Somewhere</h1>
      </header>
      <Timezones />
      <Glass />
    </div>
  );
}

export default App;
